import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { permissions } from '../../../../constants/common';

import { isPermissionAccept } from '../../../../utils';

import Nav from '../../../Includes/Nav/Nav';

class HeaderPrimaryNav extends React.PureComponent {
  render() {
    const { user } = this.props;

    this.list = [
      ...user.admin_permissions.includes('elama') ? [{
        key: 'elama',
        itemClassName: null,
        link: '/elama',
        text: 'Пользователи eLama',
        condition: isPermissionAccept(user, permissions.ELAMA),
      }] : [],
      {
        key: 'users',
        itemClassName: null,
        link: '/users',
        text: 'Пользователи',
        condition: isPermissionAccept(user, permissions.USERS),
      },
      {
        key: 'invoices',
        itemClassName: null,
        link: '/invoices',
        text: 'Счета',
        condition: isPermissionAccept(user, permissions.INVOICES),
      },
      {
        key: 'partners',
        itemClassName: null,
        link: '/partners',
        text: 'Партнеры',
        condition: isPermissionAccept(user, permissions.PARTNERS),
      },
      {
        key: 'mailing',
        itemClassName: null,
        link: '/mailing',
        text: 'Рассылки',
        condition: isPermissionAccept(user, permissions.MAILING),
      },
      {
        key: 'promocodes',
        itemClassName: null,
        link: '/promocodes',
        text: 'Промокоды',
        condition: isPermissionAccept(user, permissions.SERVICES),
      },
      {
        key: 'templates',
        itemClassName: null,
        link: '/templates',
        text: 'Шаблоны',
        condition: isPermissionAccept(user, permissions.TEMPLATES),
      },
      // {
      //   key: 'videos',
      //   itemClassName: null,
      //   link: '/videos',
      //   text: 'Видео',
      //   condition: isPermissionAccept(user, permissions.VIDEOS),
      // },
      {
        key: 'objects',
        itemClassName: null,
        link: '/objects',
        text: 'Объекты',
        condition: isPermissionAccept(user, permissions.OBJECTS),
      },
      {
        key: 'landing',
        itemClassName: null,
        link: '/landing/pages',
        text: 'Лендинг',
        condition: isPermissionAccept(user, permissions.LANDING),
      },
      {
        key: 'more',
        condition: true,
        itemClassName: 'more',
        icon: <FontAwesomeIcon icon={['fas', 'ellipsis-h']} />,
        menu: [
          {
            key: 'ideas',
            itemClassName: null,
            link: '/ideas',
            text: 'Идеи',
            condition: isPermissionAccept(user, permissions.IDEAS),
          },
          {
            key: 'services',
            itemClassName: null,
            link: '/services?sort=desc&group=individual',
            text: 'Тарифы',
            condition: isPermissionAccept(user, permissions.SERVICES),
          },
          {
            key: 'access',
            itemClassName: null,
            link: '/access/ip-addresses',
            text: 'Доступ',
            condition: isPermissionAccept(user, permissions.ACCESS),
          },
          {
            key: 'companies',
            itemClassName: null,
            link: '/companies',
            text: 'Контрагенты',
            condition: isPermissionAccept(user, permissions.COMPANIES),
          },
          {
            key: 'creative-studio',
            itemClassName: null,
            link: '/creative-studio',
            text: 'Креативная студия',
            condition: isPermissionAccept(user, permissions.CREATIVE_STUDIO),
          },
          {
            key: 'ai-library',
            itemClassName: null,
            link: '/ai-library',
            text: 'Библиотека нейросетей',
            condition: isPermissionAccept(user, permissions.AI),
          },
        ],
      },
    ];

    return <Nav semantic list={this.list} />;
  }
}

HeaderPrimaryNav.propTypes = {
  user: PropTypes.object,
};

export default HeaderPrimaryNav;
